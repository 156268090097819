@use "sass:math";
@import "/node_modules/bootstrap/scss/functions";
@import "/node_modules/bootstrap/scss/variables";
@import "/node_modules/bootstrap/scss/mixins/_breakpoints";
@import "variables.module";


$screenSizes: sm, md, lg, xl, xxl;
$h7-font-size: $h6-font-size * 0.9;
$fontSizes: $h1-font-size, $h2-font-size, $h3-font-size, $h4-font-size, $h5-font-size, $h6-font-size, $h7-font-size;
$small: 0.8;
$smaller: 0.65;
$index: 0;
@mixin small($x) {
  &.small {
    font-size: $x * $small;
  }

  &.smaller {
    font-size: $x * $smaller;
  }
}

@mixin styleWithScreenSize {
  @each $fontSize in $fontSizes {
    $smallFS: $fontSize * $small;
    $smallerFS: $fontSize * $smaller;
    $index: $index+1;

    .h#{$index}.small, .h#{$index}-small {
      font-size: $smallFS;
    }
    .h#{$index}.smaller, .h#{$index}-smaller {
      font-size: $smallerFS;
    }

    @each $size in $screenSizes {
      @include media-breakpoint-up($size) {
        .h#{$index}-#{$size} {
          font-size: $fontSize;
        }
        .h#{$index}-#{$size}.small, .h#{$index}-#{$size}-small {
          font-size: $smallFS;
        }
        .h#{$index}-#{$size}.smaller, .h#{$index}-#{$size}-smaller {
          font-size: $smallerFS;
        }
      }
    }
  }


  .w-fit-content {
    width: fit-content;
  }
  .mw-fit-content {
    min-width: fit-content;
  }

  .maxW-fit-content {
    max-width: fit-content;
  }


  .hc-100 {
    height: 100% !important;

    & > * {
      height: 100% !important;
    }
  }

  .wc-100 {
    width: 100% !important;

    & > * {
      width: 100% !important;
    }
  }

  .hCSpan-100 {
    height: 100% !important;

    & > span {
      height: 100% !important;
    }
  }

  .wCSpan-100 {
    width: 100% !important;

    & > span {
      width: 100% !important;
    }
  }
}

.h7 {
  font-size: $h7-font-size;
}

@include styleWithScreenSize;


$columns: 12;
@mixin maxLineList {
  @for $i from 1 through $columns {
    .maxLine-#{$i} {
      @include maxLine($i);
    }
    @include media-breakpoint-up(sm) {
      .maxLine-sm-#{$i} {
        @include maxLine($i);
      }
    }

    @include media-breakpoint-up(md) {
      .maxLine-md-#{$i} {
        @include maxLine($i);
      }
    }

    @include media-breakpoint-up(lg) {
      .maxLine-lg-#{$i} {
        @include maxLine($i);
      }
    }

    @include media-breakpoint-up(xl) {
      .maxLine-xl-#{$i} {
        @include maxLine($i);
      }
    }

    @include media-breakpoint-up(xxl) {
      .maxLine-xxl-#{$i} {
        @include maxLine($i);
      }
    }
  }
}

@include maxLineList;

@mixin widthList {
  @for $i from 1 through 20 {
    .w-#{math.div(($i*10),2)} {
      width: math.div(($i*10%), 2);
    }
    .mw-#{math.div(($i*10),2)} {
      min-width: math.div(($i*10%), 2);
    }
    .maxW-#{math.div(($i*10),2)} {
      max-width: math.div(($i*10%), 2);
    }

    @include media-breakpoint-up(sm) {
      .w-sm-#{math.div(($i*10),2)} {
        width: math.div(($i*10%), 2) !important;
      }
      .mw-sm-#{math.div(($i*10),2)} {
        min-width: math.div(($i*10%), 2) !important;
      }
      .maxW-sm-#{math.div(($i*10),2)} {
        max-width: math.div(($i*10%), 2) !important;
      }
    }

    @include media-breakpoint-up(md) {
      .w-md-#{math.div(($i*10),2)} {
        width: math.div(($i*10%), 2) !important;
      }
      .mw-md-#{math.div(($i*10),2)} {
        min-width: math.div(($i*10%), 2) !important;
      }
      .maxW-md-#{math.div(($i*10),2)} {
        max-width: math.div(($i*10%), 2) !important;
      }
    }

    @include media-breakpoint-up(lg) {
      .w-lg-#{math.div(($i*10),2)} {
        width: math.div(($i*10%), 2) !important;
      }
      .mw-lg-#{math.div(($i*10),2)} {
        min-width: math.div(($i*10%), 2) !important;
      }
      .maxW-lg-#{math.div(($i*10),2)} {
        max-width: math.div(($i*10%), 2) !important;
      }
    }

    @include media-breakpoint-up(xl) {
      .w-xl-#{math.div(($i*10),2)} {
        width: math.div(($i*10%), 2) !important;
      }
      .mw-xl-#{math.div(($i*10),2)} {
        min-width: math.div(($i*10%), 2) !important;
      }
      .maxW-xl-#{math.div(($i*10),2)} {
        max-width: math.div(($i*10%), 2) !important;
      }
    }

    @include media-breakpoint-up(xxl) {
      .w-xxl-#{math.div(($i*10),2)} {
        width: math.div(($i*10%), 2) !important;
      }
      .mw-xxl-#{math.div(($i*10),2)} {
        min-width: math.div(($i*10%), 2) !important;
      }
      .maxW-xxl-#{math.div(($i*10),2)} {
        max-width: math.div(($i*10%), 2) !important;
      }
    }
  }
}

@include widthList;


h1, h2, h3, h4, h5, h6, p, span, a, i, .h7 {
  margin: 0;
  padding: 0;
  font-weight: inherit;
}

.shadow-none {
  &, &:hover, &:active, &:focus {
    box-shadow: unset !important;
  }
}

.border-none {
  &, &:hover, &:active, &:focus {
    border-width: 0 !important;
  }
}

.fw-semiBold {
  font-weight: 600;
}

.mr-0 {
  margin-right: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-1 {
  margin-right: .25rem !important;
}

.ml-1 {
  margin-left: .25rem !important;
}

.mr-2 {
  margin-right: .5rem !important;
}

.ml-2 {
  margin-left: .5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.mr-auto {
  margin-right: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-1 {
  padding-right: .25rem !important;
}

.pl-1 {
  padding-left: .25rem !important;
}

.pr-2 {
  padding-right: .5rem !important;
}

.pl-2 {
  padding-left: .5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pr-5 {
  padding-right: 3rem !important;
}

.pl-5 {
  padding-left: 3rem !important;
}

.transition-300 {
  @include transition(all 300ms ease);

}
