@import "variables.module";

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

@each $cl in $colors {
  #{'.bg_' + str-replace($cl+"", "#")} {
    background-color: $cl !important;
  }
  #{'.color_' + str-replace($cl+"", "#")} {
    color: $cl !important;
  }
}

.color_fff, .color_ffffff {
  color: white !important;
}

.bg_fff, .bg_ffffff {
  background-color: white !important;
}

.color_f5f5f5 {
  color: whitesmoke !important;
}

.color_808080 {
  color: gray !important;
}

.color_000, .color_000000 {
  color: black !important;
}

.color_primary {
  color: $primary !important;
}

.bg_primary {
  background-color: $primary !important;
}

.color_secondary {
  color: $secondary !important;
}

.bg_secondary {
  background-color: $secondary !important;
}

.color_link {
  color: $link !important;
}

.color_inputPlaceHolder {
  color: $inputPlaceholderColor !important;
}

.color_inputColor {
  color: $inputColor !important;
}


