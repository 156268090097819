@import "variables.module";
@import "color";
@import "./datePicker.module";
@import "./globalsAnimation";
@import "./dots";
@import "./test.module";
@import "./heart.svg";


$theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
);

@import "/node_modules/bootstrap/scss/bootstrap";
@import "./customBootstrap";


style {
  display: none !important;
}

html,
body {
  margin: 0;
  font-family: Nunito, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  //overflow-x: hidden !important;
  max-width: 100vw;
  color: $tc_444444;

  &:not(.modal-open) {
    padding: 0 !important;
  }
}

.App {
  display: flex;
  align-items: center;
  height: 100vh;
  padding: 40px 5px;
}

.btn-outline-primary:hover {
  color: #fff !important;
}

form.was-validated input.form-control:valid ~ .flag-dropdown, form.was-validated input.form-control:valid ~ button[psb] {
  border-color: $success;
}

html {
  //overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

//h1, h2, h3, h4, h5, h6, p {
//  color: $tc_444444;
//}
//
//.h1, .h2, .h3, .h4, .h5, .h6 {
//  color: unset;
//}


a[href][nbhs=true]:hover {
  color: unset !important;
}

a:hover {
  color: inherit;
  cursor: default;
}

a[href]:hover, .hSecondary:hover {
  cursor: pointer;
  color: $secondary !important;
}

a[href].primaryHover:hover, .primaryHover:hover {
  color: $primary !important;
  @include transition(color 400ms ease)
}

*[role=button], *[role=button]:hover, a, a:hover {
  @include transition(color 400ms ease)
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=checkbox] {
  width: 1.3em;
  height: 1.3em;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

.text-dark {
  color: #000 !important;
}

.selectDisable, img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.imgTransition {
  @include transition(all 400ms ease);
}

$offset: 100px;
$offsetSm: 60px;
#siteRoot:not([sm-header-disabled]) {
  margin-top: $offsetSm;
  @include media-breakpoint-up(lg) {
    margin-top: $offset;
  }
}

#siteRoot:not([lg-header-disabled]) {
  @include media-breakpoint-up(lg) {
    margin-top: $offset;
  }
}

.sticky-top {
  top: $offset;
}

.react-loading-skeleton {
  line-height: 1.2;
}

.swiper {
  width: 100% !important;
}

.swiper-wrapper {
  max-height: 100% !important;
  height: 100% !important;
  display: flex !important;
}

.ws-pre {
  white-space: pre;
}

iframe {
  max-width: 100%;
  height: auto;
}

body.modal-open {
  //height: 100vh;
}

div[role=dialog] {
  padding: 0 !important;
}

.country-list {
  z-index: 99 !important;
}

.swiper-button-prev, .swiper-button-next {
  display: none;
}

//GoogleMap
.gm-style {
  & .gm-style-iw-t {
    display: none;
  }
}

.animate__animated {
  opacity: 0;
}

.animate__animated.active {
  opacity: 1 !important;
}

ul.mgUl, ol.mgOl {
  margin: 0;
  padding: 0;
  list-style: none;
}

.none-shadow {
  &, &:hover, &:active, &:focus {
    box-shadow: unset !important;
  }
}

.none-shadow-with-child {
  &, &:hover, &:active, &:focus, & *, &:hover *, &:active *, &:focus * {
    box-shadow: unset !important;
  }
}

.none-border {
  &, &:hover, &:active, &:focus {
    border-width: 0 !important;
  }
}

.rc-tooltip {
  opacity: 1;
}

.rc-tooltip-inner {
  background-color: #fff;
}

.rc-tooltip-inner {
  background-color: #fff !important;
}

.rc-tooltip-arrow, .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow, .rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  border-bottom-color: $tc_d8d3d3 !important;
}


#siteRoot.doneIntro {
  opacity: 1;
  @include transition(all 3400ms ease);
}

#siteRoot.intro {
  opacity: 0;
}

#siteRoot.exit {
  opacity: 0;
  @include transition(all 2000ms ease);
}

.oneLine {
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}


.custom-switch-button, .custom-switch-button * {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}


$width: 80px;
.custom-switch-button {
  background: rgba(255, 255, 255, 0.56);
  border-radius: $width /4;
  overflow: hidden;
  width: $width * 2;
  text-align: center;
  font-size: 8px;
  letter-spacing: 1px;
  color: $primary;
  position: relative;
  padding-right: $width;
  border: 2px solid $primary;
}

.custom-switch-button.changed {
  color: #fff !important;
}

.custom-switch-button.changed label {
  color: $primary !important;
}

.custom-switch-button label {
  color: #fff;
}

.custom-switch-button:before {
  content: "New Website";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: $width;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  pointer-events: none;
}

.custom-switch-button-checkbox {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}

.custom-switch-button-checkbox:checked {
  color: #444444;
}

.custom-switch-button-checkbox:checked + .custom-switch-button-label:before {
  transform: translateX($width);
  transition: transform 300ms linear;
}

.custom-switch-button-checkbox + .custom-switch-button-label {
  position: relative;
  padding: 15px 0;
  display: block;
  user-select: none;
  pointer-events: none;
}

.custom-switch-button-checkbox + .custom-switch-button-label:before {
  content: "";
  background: $primary;
  height: 100%;
  width: $width;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: $width /4;
  transform: translateX(0);
  transition: transform 300ms;
}

.custom-switch-button-checkbox + .custom-switch-button-label .custom-switch-button-label-span {
  position: relative;
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 30px;
    background: $inputBackground; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ebebeb;
    border: 3px solid #fafafa;
  }
}

body.fadeOutBody {
  opacity: 0;
  -webkit-transition: all 3s ease;
  -moz-transition: all 3s ease;
  -ms-transition: all 3s ease;
  -o-transition: all 3s ease;
  transition: all 3s ease;
}

@media only screen and (max-width: 1127px) {
  .custom-switch-button.lg {
    display: none;
  }

  .custom-switch-button.sm {
    display: block;
    background: #fff;
  }
}


.rc-tooltip-arrow, .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow, .rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  border-bottom-color: #fff !important;
}

.rc-slider-track {
  background-color: $primary;
}

.rc-slider-handle {
  border-color: $tc_e0e0e0;

  &:hover, &:focus {
    border-color: $primary;
  }

  &:active {
    border-color: $primary;
    box-shadow: 0 0 5px $primary_light;
  }
}

.tabListItem {
  clear: both;
  display: inline-block;
  white-space: nowrap;
}

.page-loading-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.49);
  z-index: 1029;
  backdrop-filter: blur(5px);
}
