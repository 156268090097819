@import "./variables.module";
@import "/node_modules/bootstrap/scss/functions";
@import "/node_modules/bootstrap/scss/variables";
@import "/node_modules/bootstrap/scss/mixins/_breakpoints";

.DateRangePicker {
  width: 100%;
}

.DateInput_input__focused {
  border-width: 0;
}

.DateRangePickerInput_clearDates {
  display: inline-flex;
}

.DateRangePicker_picker {
  border-radius: 16px;
  z-index: 3;
  box-shadow: $boxShadow1;

  & > div {
    border-radius: 16px;
    overflow: hidden;
  }
}

.DateRangePickerInput {
  display: flex;
  align-items: center;
  overflow: hidden;

  @include media-breakpoint-down(lg) {
    background-color: $tc_ffeadc;
    border-radius: 8px;
    overflow: hidden;
    & .DateInput {
      min-width: 50%;
      max-width: 45vw;


      & input {
        background-color: $tc_ffeadc;
      }
    }
  }

  & .DateRangePickerInput_arrow {
    height: 30px;
  }

  & .DateInput {
    min-width: 50%;
    max-width: 45vw;


    & input {
      padding: 11px 8px;
      text-align: center;
      font-size: 1rem;
      color: $tc_c4c4c4;
      @include placeholderColor($tc_c4c4c4);
    }
  }
}

.CalendarDay {
  border-width: 0 !important;
  padding: 0;
  background: transparent !important;

  & > div {
    margin: -1px -1px;
    padding: 1px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background: $primary_light;
}

.CalendarDay__selected_span, .CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
  & > div {
    background: $primary_light;
    color: #fff;
  }
}

.CalendarDay__hovered_span:active {
  & > div {
    background: $primary;
  }
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  & > div {
    background: $primary;
    border-radius: 50%;
    position: relative;
  }
}

.CalendarDay__selected_start {
  background: $primary_light !important;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.CalendarDay__selected_end {
  background: $primary_light !important;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.divider {
  height: 25px;
}
