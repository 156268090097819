@import "./variables.module";

@mixin animation-mixin($name, $from, $to) {

  @-moz-keyframes #{$name} {
    0% {
      -moz-transform: $from;
    }
    100% {
      -moz-transform: $to;
    }
  }

  @-webkit-keyframes #{$name} {
    0% {
      -webkit-transform: $from;
    }
    100% {
      -webkit-transform: $to;
    }
  }

  @keyframes #{$name} {
    0% {
      transform: $from
    }
    100% {
      transform: $to
    }
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinEnd90deg {
  0% {
    -moz-transform: rotate(-90deg);
  }
  100% {
    -moz-transform: rotate(90deg);
  }
}

@-webkit-keyframes spinEnd90deg {
  0% {
    -webkit-transform: rotate(-90deg);
  }
  100% {
    -webkit-transform: rotate(90deg);
  }
}

@keyframes spinEnd90deg {
  0% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}


@-moz-keyframes signal2 {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba($primary, 0.5);
    box-shadow: 0 0 0 0 rgba($primary, 50%);
  }
  70% {
    -moz-box-shadow: 0 0 0 15px rgba($primary, 0.3);
    box-shadow: 0 0 0 15px rgba($primary, 30%);
  }
  100% {
    -moz-box-shadow: 0 0 0 10px rgba($primary, 0);
    box-shadow: 0 0 0 10px rgba($primary, 0%);
  }
}

@-webkit-keyframes signal2 {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba($primary, 0.5);
    box-shadow: 0 0 0 0 rgba($primary, 50%);
  }
  70% {
    -moz-box-shadow: 0 0 0 15px rgba($primary, 0.3);
    box-shadow: 0 0 0 15px rgba($primary, 30%);
  }
  100% {
    -moz-box-shadow: 0 0 0 10px rgba($primary, 0);
    box-shadow: 0 0 0 10px rgba($primary, 0%);
  }
}

@keyframes signal2 {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba($primary, 0.5);
    box-shadow: 0 0 0 0 rgba($primary, 50%);
  }
  70% {
    -moz-box-shadow: 0 0 0 15px rgba($primary, 0.3);
    box-shadow: 0 0 0 15px rgba($primary, 30%);
  }
  100% {
    -moz-box-shadow: 0 0 0 10px rgba($primary, 0);
    box-shadow: 0 0 0 10px rgba($primary, 0%);
  }
}
