@import "/node_modules/bootstrap/scss/mixins/breakpoints";
@import "/node_modules/bootstrap/scss/functions";
@import "/node_modules/bootstrap/scss/variables";
@import "/node_modules/bootstrap/scss/mixins/breakpoints";
@import "./style/variables.module.scss";

body{
  background-color: #00000000;
}

.londonistAuth {
  display: flex;

  height: 100vh;

  & .leftSide {
    width: 45%;
    background: url("./assets/login_lg.png") no-repeat top center;
    background-size: cover !important;

    & > div {
      display: flex;
      justify-content: center;
      padding-top: 25%;
      height: 100%;
    }

    & .welcome {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      color: #fff;
      font-size: 30px;

      & .logo {
        padding-top: 4px;
        width: 70%;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  & .rightSide {
    width: 55%;
    height: 100%;

    & > div {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-left: 10%;
      padding-right: 10%;
      margin-top: -10%;

      & h4 {
        font-size: 28px;
        color: #363636;
      }
    }
  }

}

.londonistButton {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: $componentBorderRadius;
  box-shadow: unset !important;

  &.normalRadius {
    border-radius: 5px;
  }

  &.btn-primary {
    background-color: $primary !important;
    border-color: $primary !important;
  }

  &.btn-secondary {
    background-color: $secondary !important;
    border-color: $secondary !important;
  }

  &.btn-secondary, &.btn-primary {
    color: #fff;
    @include transition(all 300ms ease);

    &:hover {
      @include transition(all 300ms ease);
    }
  }


  &.btn-outline-white {
    color: #fff;
    border-color: #fff;
    box-shadow: unset !important;
  }

  &.btn-outline-primary:hover {
    color: #fff;
  }

  &.btn-shadow {
    color: #000000 !important;
    border: 1px solid #E9E9E9;
    -webkit-box-shadow: 0 0 6px 1px #E9E9E9;
    box-shadow: 0 0 6px 1px #E9E9E9;
  }

  &.btn-link, &.btn-link-primary .btn-text {
    color: #000000 !important;
    border-width: 0;
    text-decoration: underline;
    box-shadow: unset !important;
  }

  &.btn-link-primary {
    color: $primary !important;

    &:hover {
      color: $primary_light !important;
    }
  }

  &.btn-text {
    text-decoration: unset;
    color: #000;
  }
}

.defaultAnimation {
  position: relative;
  overflow: hidden;
  //background: transparent !important;

  &.btn-primary {
    & > span {
      background: darken($primary, 0.3);

    }

    &:hover {
      background-color: $primary !important;
      border-color: $primary !important;
    }
  }


  &.btn-primary {
    & > span {
      &:before {
        background: darken($primary, 10);
      }

      &:hover {
        color: #fff !important;
      }
    }

    &:hover {
      background-color: $primary !important;
      border-color: $primary !important;
    }
  }

  &.btn-secondary {
    & > span {
      &:before {
        background: darken($secondary, 10);
      }

      &:hover {
        color: #fff !important;
      }
    }

    &:hover {
      background-color: $secondary !important;
      border-color: $secondary !important;
    }
  }

  & > span {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    z-index: 1;
    justify-content: center;

    &:before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      border-radius: 50%;
      transition: all 650ms ease-in-out;
    }

  }

  &:hover {

    & > span {
      &:before {
        content: "";
        width: 400px;
        height: 400px;
        transition: all 1000ms ease-in-out;
      }
    }
  }
}

.londonistInput {
  font-size: 1rem;

  & * {
    border-color: $tc_e0e0e0;
  }

  & > .input-group {


    & > * {
      background-color: $inputBackground;
    }

    & input, & textarea {
      background-color: $inputBackground;

      &::placeholder {
        color: $inputPlaceholderColor;
      }

      &-ms-input-placeholder {
        color: $inputPlaceholderColor;
      }

      &::-ms-input-placeholder {
        color: $inputPlaceholderColor;
      }
    }

    &[has-right-action] {
      & input {
        border-right-width: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      & > *:not(input):not(:first-child) {
        background-color: $inputBackground;
        border: 1px solid $inputBorderColor;
        border-top-right-radius: $inputBorderRadius;
        border-bottom-right-radius: $inputBorderRadius;
        border-left-width: 0;
      }
    }

    &[has-left-action] {
      & input {
        border-left-width: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      & > *:not(input):first-child {
        background-color: $inputBackground;
        border: 1px solid $tc_ced4da;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-right-width: 0;
      }
    }
  }

  &[invalid] {
    & > .input-group {
      & .form-control,
      &[has-right-action] > *:not(input):not(:first-child),
      &[has-left-action] > *:not(input):first-child {
        border-color: $danger;
      }
    }
  }

  &[valid] {
    & > .input-group {
      & .form-control,
      &[has-right-action] > *,
      &[has-left-action] > * {
        border-color: $success;
      }
    }
  }
}

.w-100 {
  width: 100%;
}

.londoistPhoneInput {
  width: 100%;

  & > div {
    & input.form-control {
      width: 100%;
      height: auto;
      line-height: 1.5;
      font-size: 0.9rem;
      border-color: $tc_e0e0e0;
      background-color: $tc_fafafa;
      padding: 0.5rem 0.75rem !important;
      padding-left: 58px !important;
    }

    & div.flag-dropdown {
      border-color: $tc_e0e0e0;
      background-color: $tc_fafafa;
      border-style: solid;
      border-width: 1px;
      border-right: 0;
      border-right: unset;

      & .selected-flag:before {
        content: unset;
      }
    }

  }

  &[valid] {
    & input {
      border-color: $success !important;
    }

    & > div > div.flag-dropdown {
      border-color: $success !important;
    }
  }
}

.londonistSelectRoot100 {
  display: flex;
  min-height: 100%;

  & > div[class*='control'] {
    min-height: 100%;
    width: 100%;

    & > div {
      border-width: 0 !important;
    }

    &:hover {
      border-color: $inputBorderColor !important;
    }
  }
}

.londonistSelectRoot {
  height: 100%;
  font-size: 1rem;
  width: 100%;

  & > div[class*='control'] {
    border-radius: $inputBorderRadius;
    border: 1px solid $inputBorderColor;
    width: 100% !important;
    padding: 8px;
    background-color: $inputBackground;
  }


  & div[class*=menu] > div {
    overflow-x: hidden;
  }

  & div[class*='menu'] {
    margin-top: 0;
    min-width: 100%;
    border: 1px solid $tc_ebebeb;
    padding-left: 0;

    & > div {
      padding: 0;
      max-height: 200px;
      background-color: $inputBackground;

      //&::-webkit-scrollbar {
      //  width: 12px;
      //}
      //
      //&::-webkit-scrollbar-track {
      //  border-radius: 30px;
      //  background: $inputBackground; /* color of the tracking area */
      //}
      //
      //&::-webkit-scrollbar-thumb {
      //  background-color: $tc_ebebeb; /* color of the scroll thumb */
      //  border-radius: 45%;
      //  border: 7px solid #fafafa;
      //}


      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 30px;
        background: $inputBackground; /* color of the tracking area */
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ebebeb;
        border: 3px solid #fafafa;
      }


      & > div {
        background-color: $inputBackground;
        border-right: 1px solid $tc_ebebeb;
        padding-left: 1rem;
        color: $inputColor;
        @include transition(all 200ms ease);

        &:hover {
          @include transition(all 200ms ease);
          background-color: $tc_ebebeb;
        }
      }
    }

  }

  & div[class*='control'] {
    width: max-content;
    white-space: pre;
    font-size: 1rem;
    box-shadow: unset;
    height: 100%;
    background-color: $inputBackground;
    border-color: $inputBorderColor;
    padding: 0;

    & ~ div[class*=menu] {
      min-width: 150px;
    }

    & > div:first-child {
      height: 100%;


      & > div {
        font-size: 1rem;
        color: $tc_222222;
        display: flex;
        align-items: center;
      }
    }

    & > div:last-child {
      display: none;
    }

  }


  & div[class*='menu'] {
    width: auto;
    z-index: 99;
  }

  & div[class*='singleValue'] {
    color: #fff;
  }
}

#login-modal {
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 5px 1px rgba(0, 0, 2, 0.1);
}

.londonistLoginModalRoot {
  display: flex !important;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;
  border-radius: 8px;

  & > div {
    width: 60vw;
    max-width: unset;

    @include media-breakpoint-down(xl) {
      width: 80vw;
    }
    @include media-breakpoint-down(sm) {
      width: 90vw;
    }

    & > div {
      border-radius: 0;
      overflow: hidden;

      & > div {
        min-height: 80vh;

        @include media-breakpoint-down(sm) {
          min-height: 60vw;
        }
      }
    }
  }
}

.londonistLoginModalLeftContainer {
  background: $primary url("../londonistAuth/assets/login_lg.png") no-repeat top center;
  background-size: cover !important;
  padding-top: 10%;
  border-radius: 0;

  & > * {
    padding-top: 20%;
  }
}

.londonistLoginModalRightRoot {
  background-color: #fff;
}

.londonistLoginModalRightContainer {
  width: 80%;

  @include media-breakpoint-down(md) {
    width: 90%;
  }
  @include media-breakpoint-down(sm) {
    width: 95%;
  }
}

.londonistLoginModalCloseButton {
  position: absolute;
  right: 30px;
}

.londonistLoginModalBackButton {
  position: absolute;
  left: 30px;
}

.londonistLoginModalDataForm {
  & input {
    min-height: 3rem !important;
    font-size: 1.1rem !important;

    &::-webkit-input-placeholder {
      font-size: 0.9rem !important;
    }
  }

  & > div > div {
    &.react-tel-input {
      & div.flag-dropdown div.selected-flag .flag {
        transform: scale(1.2);
      }
    }
  }
}

.londonistLoginModalCodeInput {
  & > div {
    & .react-code-input {
      width: 100%;
      display: flex;

      & input {
        width: 45px !important;
        height: 45px !important;
        max-width: 14%;
        border-radius: 0 !important;
        box-shadow: unset !important;
        text-align: center;
        padding: 0 !important;
        outline: unset !important;
        border-width: 2px !important;
        border-color: $primary !important;

        &[value]:not([value=""]) {
          background-color: $primary !important;
          color: #fff !important;
        }

        &:first-child {
          margin-left: 0 !important;
        }

        &:last-child {
          margin-right: 0 !important;
        }
      }
    }
  }
}

.londonistAuth {
  display: flex;

  height: 100vh;

  & .leftSide {
    width: 45%;
    background: url("../londonistAuth/assets/login_lg.png") no-repeat top center;
    background-size: cover !important;

    & > div {
      display: flex;
      justify-content: center;
      padding-top: 25%;
      height: 100%;
    }

    & .welcome {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      color: #fff;
      font-size: 30px;

      & .logo {
        padding-top: 4px;
        width: 70%;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  & .rightSide {
    width: 55%;
    height: 100%;

    & > div {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-left: 10%;
      padding-right: 10%;
      margin-top: -10%;

      & h4 {
        font-size: 28px;
        color: #363636;
      }
    }
  }

}

.londonist-tab {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  display: flex;

  & > span {
    min-width: 33.333%;
    margin-right: 15px;
    text-align: start;
    cursor: pointer;

    //&:first-child.active ~ i {
    //  transform: translateX(4%) scaleX(0.15);
    //}
    //
    //&:nth-child(2).active ~ i {
    //  transform: translateX(38.333%) scaleX(0.15);
    //}
  }

  & > i {
    position: absolute;
    bottom: -8px;
    left: 0;
    height: 3px;
    background-color: #ff9001;
    transform-origin: 0 0;
    transition: transform .25s;
    width: 0
  }

  &:after {
    content: ' ';
    display: table;
    clear: both;
  }
}
