$input-btn-padding-y: 0.5rem;
$input-btn-font-size: 0.9rem;
$primary_darker: #ea6a11;
$primary_dark: #fc934a;
$primary: #ffa363;
$primary_light: #f3ae7d;
$primary_lighter: #f3c2a0;
$primary_opacity: #ffdfc9;
$secondary_darker: #7da169;
$secondary_dark: #8cb078;
$secondary: #9ec389;
$boxShadow1: 0 0 8px rgb(129 129 129 / 20%);
$boxShadowPrimary1: 0px 4px 24px rgba(255, 163, 99, 0.3);
$icomoon-font-path: "../../public/fonts/icomoon";
$enable-negative-margins: true;
$enable-validation-icons: false;
$danger: #dc3545;
$success: #28a745;
$link: #1f93ff;
$tc_ffeadcHover: #eacfc3;
$tc_star: #fff0bd;
$tc_star_active: #ffd233;
$bgComponentBorderRadius: 80px;
$componentBorderRadius: 0 10px 0 10px;
$componentBorderRadiusReverse: 10px 0 10px 0;
$inputBackground: #fafafa;
$inputBorderColor: #e0e0e0;
$inputPlaceholderColor: #c4c4c4;
$inputColor: #444444;
$inputBorderRadius: 0.25rem;


@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}


$tc_000000: black;
$tc_222222: #222222;
$tc_263238: #263238;
$tc_444444: #444444;
$tc_4a4a4a: #4a4a4a;
$tc_565656: #565656;
$tc_6d6d6d: #6d6d6d;
$tc_808080: gray;
$tc_8e8e8e: #8e8e8e;
$tc_f8f8f8: #f8f8f8;
$tc_d8d3d3: #d8d3d3;
$tc_e0e7ec: #e0e7ec;
$tc_c4c4c4: #c4c4c4;
$tc_f5f5f5: whitesmoke;
$tc_ffeadc: #ffeadc;
$tc_9f9f9f: #9f9f9f;
$tc_f9f9f9: #f9f9f9;
$tc_eeeeee: #eeeeee;
$tc_e5e5e5: #e5e5e5;
$tc_e0e0e0: #e0e0e0;
$tc_f6f4f2: #f6f4f2;
$tc_f7f7f7: #f7f7f7;
$tc_e8e8e8: #e8e8e8;
$tc_eaeaea: #eaeaea;
$tc_ced4da: #ced4da;
$tc_e9e9e9: #e9e9e9;
$tc_fafafa: #fafafa;
$tc_ebebeb: #ebebeb;
$tc_ffffff: white;

$colors: (
        $tc_ffeadc,
        $tc_222222,
        $tc_263238,
        $tc_444444,
        $tc_4a4a4a,
        $tc_565656,
        $tc_6d6d6d,
        $tc_8e8e8e,
        $tc_f8f8f8,
        $tc_d8d3d3,
        $tc_e0e7ec,
        $tc_c4c4c4,
        $tc_9f9f9f,
        $tc_eeeeee,
        $tc_e5e5e5,
        $tc_e0e0e0,
        $tc_f6f4f2,
        $tc_f7f7f7,
        $tc_e8e8e8,
        $tc_eaeaea,
        $tc_ced4da,
        $tc_e9e9e9,
        $tc_f9f9f9,
        $tc_fafafa,
        $tc_ebebeb,
);


//export for use color in js file
:export {
  primary: $primary;
  secondary: $secondary;
  tc_c4c4c4: $tc_c4c4c4;
  tc_e5e5e5: $tc_e5e5e5;
  tc_d8d3d3: $tc_d8d3d3;
  tc_f7f7f7: $tc_f7f7f7;
  tc_e8e8e8: $tc_e8e8e8;
  tc_8e8e8e: $tc_8e8e8e;
  tc_eaeaea: $tc_eaeaea;
  tc_e0e0e0: $tc_e0e0e0;
  tc_9f9f9f: $tc_9f9f9f;
  tc_eeeeee: $tc_eeeeee;
  tc_ebebeb: #ebebeb;
}

@mixin transition($x...) {
  -webkit-transition: $x;
  -moz-transition: $x;
  -ms-transition: $x;
  -o-transition: $x;
  transition: $x;
}

@mixin animation($x...) {
  -webkit-animation: $x;
  -moz-animation: $x;
  animation: $x;
}

@mixin placeholderColor($x...) {
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $x;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $x;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: $x;
  }
}

@mixin maxLine($x) {
  display: -webkit-box;
  -webkit-line-clamp: $x;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin iconFontFamily() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@function randomNum($min, $max) {
  $rand: random();
  $randomNum: $min + floor($rand * (($max - $min) + 1));

  @return $randomNum;
}

