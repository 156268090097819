@import "./variables.module";

.dot-flashing {
  position: relative;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: $primary;
  color: $primary;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;

  &.dark {
    animation-name: dotFlashingDark;

    &:after, &:before {
      animation-name: dotFlashingDark;
    }
  }
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -13px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: $primary;
  color: $primary;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 13px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: $primary;
  color: $primary;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;

}


@keyframes dotFlashing {
  0% {
    background-color: $primary;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}

@keyframes dotFlashingDark {
  0% {
    background-color: $tc_444444;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}